/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper, gsap) => {
		/*
		|
		| Constants
		|-----------
		*/
		const
			$body = $('body')
			;

		/*
		|
		| Page Transition
		|-----------
		*/

		$(document).on("click", "a", function (e) {
			if (
				this.href &&
				!this.getAttribute('href').startsWith('#') &&
				this.target != "_blank"
			) {
				e.preventDefault();
				var url = this.href;

				const transitionPageOut = gsap.timeline({ paused: true, onComplete: goToUrl })
				transitionPageOut.set('.page-transition', { autoAlpha: 1 })
				transitionPageOut.to('.page-transition .layer', 0.6, { yPercent: 0, ease: "expo.in" }, "-=0")

				transitionPageOut.restart();

				function goToUrl() {
					// console.log(url)
					document.location.href = url;
				}
			}


		});

		window.addEventListener('load', function (event) {
			const transitionPage = gsap.timeline({ paused: false, })
			transitionPage.to('.page-transition .layer', .6, { yPercent: -100, ease: "expo.in" }, "-=0")
			transitionPage.to('.page-transition', { autoAlpha: 0 })
			transitionPage.set('.page-transition .layer', { yPercent: 100 })
		});





		/*
		|
		| Scroll
		|-----------
		*/
		const tlHeader = gsap.timeline({ paused: true })
		tlHeader.to('#header', { y: -98 })

		var lastScroll = 0;
		var isScrolled = false;
		window.addEventListener("scroll", function () {
			var header = document.querySelector("#header");
			var currentScroll =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop ||
				0;
			var scrollDirection = currentScroll < lastScroll;
			var shouldToggle = isScrolled && scrollDirection;

			isScrolled = currentScroll > 100;
			header.classList.toggle("sticky", shouldToggle);
			if (isScrolled) {
				header.classList.toggle("nosticky", !shouldToggle);
			}

			document.documentElement.style.setProperty('--header-height', header.classList.contains('sticky') ? `${header.offsetHeight}px` : '0px')

			lastScroll = currentScroll;
		});

		/*
		|
		| Bar product 
		|-----------
		*/
		jQuery(window).scroll(function () {
			if ($(this).scrollTop() > 250) {
				$('.barfixed').fadeIn();
			} else {
				$('.barfixed').fadeOut();
			}
		});


		/*
		| 
		| Slider product
		|-----------
		*/
		$(".swiper-list-prod").each(function (i) {

			var mySwiper = new Swiper($(this)[0], {
				navigation: {
					nextEl: $(".button-next")[i],
					prevEl: $(".button-prev")[i],
					disabledClass: 'disabled_swiper_button'
				},
				mousewheel: {
					forceToAxis: true,
					invert: true
				},
				slidesPerView: 1.1,
				spaceBetween: 40,
				breakpoints: {
					768: {
						slidesPerView: 1.1
					},
					992: {
						slidesPerView: 3.5
					},
				}
			});


		});

		/*
		| 
		| Slider brochures
		|-----------
		*/
		$(".swiper-list-brochures").each(function (i) {
			var mySwiper = new Swiper($(this)[0], {
				navigation: {
					nextEl: $(".button-next")[i],
					prevEl: $(".button-prev")[i],
				},
				mousewheel: {
					forceToAxis: true,
					invert: true
				},
				slidesPerView: 1.4,
				spaceBetween: 40,
				breakpoints: {
					580: {
						slidesPerView: 1.4
					},
					768: {
						spaceBetween: 40,
						slidesPerView: 2.5
					},
					992: {
						spaceBetween: 40,
						slidesPerView: 2.5
					},
					1200: {
						spaceBetween: 40,
						slidesPerView: 3.5
					},
					1400: {
						spaceBetween: 100,
						slidesPerView: 3.5
					},
				}
			});
		});

		/*
		| 
		| Submenu
		|-----------
		*/
		if ($(window).width() > 1200) {
			$('.nav-drop', this).not('.in .nav-drop').stop(true, true).slideUp("10");
			$(".menu-item-has-children").hover(
				function () {
					$('.nav-drop', this).not('.in .nav-drop').stop(true, true).delay(50).slideDown("10");
					$(this).toggleClass('open');
				},
				function () {
					$('.nav-drop', this).not('.in .nav-drop').stop(true, true).delay(50).slideUp("10");
					$(this).toggleClass('open');
				}
			);
		}

		/*
		|
		| Témoignages
		| ------------
		*/
		$("#swiper-tem").each(function (i) {
			var mySwiper = new Swiper($(this)[0], {
				navigation: {
					nextEl: $(".button-next")[i],
					prevEl: $(".button-prev")[i],
				},
				mousewheel: {
					forceToAxis: true,
					invert: true
				},
				spaceBetween: 15,
				// loop: true,
				autoplay: {
					delay: 5000,
				}
			});
		});

		/**
		|
		| BTN Select
		|
		*/
		$(".btn-select").on("click", (e) => {
			console.log(e.target)
			e.target.classList.toggle('active')
		})

		$(".btn-select ul li").on("click", (e) => {
			let slug = e.target.dataset.slug;
			let name = $(e.target).text();
			console.log()

			$(e.target).parent().parent().find("select").val(slug)
			$(e.target).parent().parent().find("span").text(name)

			//Close
			$(e.target).parent().parent().toggleClass("active")
		})

	}
}
