/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper, gsap) => {


		/*
		|
		| Animations
		|-----------
		*/
		//Banner image parallaxy
		// gsap.to(".home .banner-home .img-intro", {
		// 	yPercent: 20,
		// 	// border: "none",
		// 	ease: "none",
		// 	scrollTrigger: {
		// 		trigger: ".home .banner-home",
		// 		scrub: true,
		// 		markers: true,
		// 		start: "top 5%",
		// 		end: "bottom top"
		// 	},
		// });

		// image parallax
		// gsap.to(".home .block-featured-app img", {
		// 	yPercent: 20,
		// 	ease: "none",
		// 	scrollTrigger: {
		// 		trigger: ".home .block-featured-app",
		// 		scrub: true,
		// 		// markers: true,
		// 	},
		// });

		//big image parallax
		gsap.to(".block-learn-more .bg", {
			yPercent: 40,
			ease: "none",
			scrollTrigger: {
				trigger: ".block-learn-more",
				scrub: true,
				// markers: true,
				start: "top 55%"
			},
		});

		//big image parallax sustainability
		gsap.to(".block-sustainability .bg", {
			yPercent: 40,
			ease: "none",
			scrollTrigger: {
				trigger: ".block-sustainability",
				scrub: true,
				// markers: true,
				start: "top 55%"
			},
		});

		/*
		|
		| Swiper products
		|-----------
		*/
		let swiperproducts = new Swiper("#swiper-products", {
			spaceBetween: 30,
			speed: 900,
			// slidesPerView: 'auto',
			watchOverflow: true,
			// centeredSlides: true,
			mousewheel: {
				forceToAxis: true,
				invert: true
			},
			slidesPerView: 1,
			breakpoints: {
				992: {
					slidesPerView: 1.5
				},
			},
			navigation: {
				nextEl: '.button-next',
				prevEl: '.button-prev'
			}
		});

		/*
		|
		| Select Accompagnement
		|-------------------
		*/
		if ($(".accomp-select").length) {
			$('.accomp-select').on('click', function () {
				$(this).toggleClass('active')
			});

			$('.accomp-select ul li').on('click', function () {
				let v = $(this).text();
				$('.accomp-select ul li').not($(this)).removeClass('active');
				$(this).addClass('active');
				$('.accomp-select label button').text(v)
			});

			$('.item-accomp').on('click', function () {
				let idtext = $(this).attr('data-accomp');
				$('.accomp-text .text').removeClass('active').slideUp();
				$('.' + idtext).addClass('active').slideDown();
			});

			$('.accomp-select button').on('DOMSubtreeModified', function () {
				$(this).addClass('active');
			});
		}


	}
}

