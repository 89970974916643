/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap) => {

        /*
        |
        | Reset filters
        |------------
        */
        const btnReset = document.querySelector("#resetFilter");
        if (btnReset) {
            btnReset.addEventListener('click', () => {
                $(".searchpage__results__listing input[type=text]").attr('value', '');
                $(".searchpage__results__filters input[type=checkbox]").attr('checked', false);

                $("#searchForm").trigger("submit");
            })
        }

        /*
        *
        * Search Filters
        * ------------
        */
        const filtersItems = document.querySelectorAll(".filter-item .header");

        function accordion() {
            this.parentNode.classList.toggle("active");
            const content = this.nextElementSibling;

            // IF open, close | else open
            if (content.style.maxHeight) content.style.maxHeight = null;
            else content.style.maxHeight = content.scrollHeight + "px";
        }
        filtersItems.forEach((el) => el.addEventListener("click", accordion));

        /*
        |
        | Concerns search
        |------------
        */
        const concerns = document.querySelectorAll(".filter-item.concerns ul li");
        if (concerns.length) {
            document.querySelector(".filter-item.concerns input[type=text]").addEventListener('keyup', function (e) {
                let val = this.value.toUpperCase();
                if (val) {
                    let matches = document.querySelectorAll('.filter-item.concerns ul li[data-name^="' + val + '"]');
                    hideApplis();
                    matches.forEach(app => {
                        app.style.display = "flex";
                    });
                }
                else {
                    displayApplis();
                }
            })
        }

        function hideApplis() {
            concerns.forEach(app => {
                app.style.display = "none";
            });
        }
        function displayApplis() {
            concerns.forEach(app => {
                app.style.display = "flex";
            });
        }

        /*
        |
        | FUNNEL 
        |------------
        */
        const funnelToggle = document.querySelector(".funnelpage__filters-toggle");
        if (funnelToggle) {
            funnelToggle.addEventListener('click', () => {
                funnelToggle.classList.toggle("opened");
                document.querySelector(".funnelpage__filters-advanced").classList.toggle("active")
            })
        }

    }
}
