/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap) => {
        // Anchors
        const anchors = document.querySelector('.anchors')
        const links = document.querySelectorAll('.anchors-link')
        const io = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const link = document.querySelector(`.anchors-link[href="#${entry.target.id}"]`)

                link.setAttribute('aria-current', entry.isIntersecting)

                entry.isIntersecting && updateAnchorsLine(link)
            })
        }, {
            rootMargin: '-25% 0% -75% 0%'
        })

        links.forEach(link => {
            const target = document.getElementById(link.getAttribute('href').replace('#', ''))

            if(!target) return

            io.observe(target)

            link.addEventListener('click', e => {
                let padding = scrollY < target.offsetTop ? 20 : document.getElementById('header').offsetHeight + 20

                if(matchMedia('screen and (max-width: 992px)').matches) padding += link.offsetHeight

                document.documentElement.style.setProperty('--scroll-padding', `${padding}px`)
            })
        })

        const updateAnchorsLine = link => {
            anchors.style.setProperty('--anchor-y', `${link.offsetTop}px`)
            anchors.style.setProperty('--anchor-x', `${link.offsetLeft}px`)
            anchors.style.setProperty('--anchor-width', `${link.offsetWidth}px`)
            anchors.style.setProperty('--anchor-height', `${link.offsetHeight}px`)
        }

        /*
        |
        | BLOCK VIDEO
        |------------
        */
        $(".block_video .iframe-wrapper").on("click", function () {
            $(".iframe-poster").fadeOut();
            $(".block_video iframe").attr('src', $(".block_video iframe").attr('src') + '&autoplay=1&mute=1&enablejsapi=1');
        });
    }
}
