/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, gsap, ImageCompare) => {

		const options = {

			// Label Defaults
			showLabels: true,
			labelOptions: {
				before: 'Before',
				after: 'After',
				onHover: false
			},
		};
		const element = document.getElementById("image-compare");
		const viewer = new ImageCompare(element, options).mount();

		$("#productContact").on("click", function () {
			let productTitle = $("h1").text();
			// console.log(productTitle);

			$("#input_3_6").val(productTitle);
		});
	}
}
