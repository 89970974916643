/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import Swiper from 'swiper/js/swiper.min';
import gsap from "gsap";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import ImageCompare from "image-compare-viewer";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollTo);

/*
|
| Importing Components
|-----------------------
*/
import customGoogleMap from '@components/custom-google-map.js';
import MaterializeForm from '@components/materialize-form.js';
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';
import bodymovin from '@components/bodymovin';
/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import news from '@pages/news.js';
import animations from '@pages/animations.js';
import contact from '@pages/contact.js';
import home from '@pages/home.js';
import newsevents from '@pages/newsevents.js';
import search from '@pages/search.js';
import product from '@pages/product.js';
import single_newsevents from '@pages/single-newsevents.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
  {
    'file': animations,
    'dependencies': [app, gsap, ScrollTrigger, Menu, Kira, bodymovin]
  },
  {
    'file': main,
    'dependencies': [app, Swiper, gsap]
  },
  {
    'file': home,
    'dependencies': [app, Swiper, gsap]
  },
  {
    'file': news,
    'dependencies': [app],
    'resolve': '#page-news-archive'
  },
  {
    'file': newsevents,
    'dependencies': [app],
    'resolve': '#page-newsevents'
  },
  {
    'file': contact,
    'dependencies': [app, MaterializeForm, customGoogleMap],
    'resolve': '#page-contact'
  },
  {
    'file': search,
    'dependencies': [app, gsap],
    'resolve': '#page-search'
  },
  {
    'file': product,
    'dependencies': [app, gsap, ImageCompare],
    'resolve': '#single-products'
  },
  {
    'file': single_newsevents,
    'dependencies': [app, gsap],
    'resolve': '#single-newsevents'
  }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

